@import "custom_variables";
// Functions for text-setting mixin
@function font-size($level) {
    @return map-get(map-get($text-settings, $level), 'font-size');
}

@function line-height($level) {
    @return map-get(map-get($text-settings, $level), 'line-height');
}

@function font-family($level) {
    @return map-get(map-get($text-settings, $level), 'font-family');
}

@function font-weight($level) {
    @return map-get(map-get($text-settings, $level), 'font-weight');
}

@function text-transform($level) {
    @return map-get(map-get($text-settings, $level), 'text-transform');
}

@function letter-spacing($level) {
    @return map-get(map-get($text-settings, $level), 'letter-spacing');
}

@mixin text-setting($level) {
    font-family: font-family($level);
    font-weight: font-weight($level);
    font-size: font-size($level);
    line-height: line-height($level);
    text-transform: text-transform($level);
    letter-spacing: letter-spacing($level);
}

//Define default header sizes
@mixin font-heading-h1 {
    @include text-setting('font-heading-h1');
}

@mixin font-heading-h2 {
    @include text-setting('font-heading-h2');
}

@mixin font-heading-h3 {
    @include text-setting('font-heading-h3');
}

@mixin font-heading-h4 {
    @include text-setting('font-heading-h4');
}

@mixin font-heading-h5 {
    @include text-setting('font-heading-h5');
}

@mixin font-heading-h6 {
    @include text-setting('font-heading-h6');
}

//Define default text sizes
@mixin font-text-xl {
    @include text-setting('font-text-xl');
}

@mixin font-text-l {
    @include text-setting('font-text-l');
}

@mixin font-text-m {
    @include text-setting('font-text-m');
}

@mixin font-text-s {
    @include text-setting('font-text-s');
}

@mixin font-text-xs {
    @include text-setting('font-text-xs');
}

@mixin font-quote {
    @include text-setting('font-quote');
}

@mixin font-history {
    @include text-setting('font-history');
}

@mixin custom-bullets($color) {
    list-style: none;
    margin-left: 12px;

    @include breakpoint(xlarge) {
        margin-left: 24px;
    }

    li {
        position: relative;
        padding-left: 12px;

        &:before {
            content: '';
            background-color: $color;
            width: 6px;
            height: 6px;
            position: absolute;
            display: block;
            top: 10px;
            left: 0;
        }
    }
}

@mixin custom-bullets-image($image, $width, $height) {
    list-style: none;
    margin-left: 0;

    li {
        position: relative;
        padding-left: 26px;
        margin-bottom: 12px;

        &:before {
            content: '';
            background-image: url("/static/default/media/images/themes/#{$theme-name}/svg/#{$image}");
            background-size: auto 100%;
            width: $width;
            height: $height;
            position: absolute;
            display: block;
            top: 6px;
            left: 0;
        }
    }
}

;@import "sass-embedded-legacy-load-done:47";