@import "../../../../src/scss/vendor/foundation/foundation_settings";
@import "../../../../src/scss/abstracts/custom_variables";
@import "../../../../src/scss/abstracts/custom_mixins";

.block_hero_home {
    overflow: hidden;
    min-height: 500px;
    padding-bottom: 40px;

    @include breakpoint(xlarge) {
        display: flex;
        padding-top: 60px;
        padding-bottom: 56px;
        min-height: 600px;
    }

    .grid-x {
        position: relative;

        @include breakpoint(xlarge) {
            height: 100%;
        }
    }

    @include breakpoint(xlarge) {
        p {
            max-width: 70%;
        }
    }

    .button {
        margin-top: 32px;

        @include breakpoint(xlarge) {
            margin-top: 80px;
        }
    }

    .image-mask {
        mask-size: 150%;
        left: 25vw;
        width: 75vw;
        mask-position: bottom left;
        z-index: 1;

        @include breakpoint(large down) {
            position: relative;
        }

        @include breakpoint(xlarge) {
            aspect-ratio: 7.8 / 5.3;
            top: 0;
            left: 48vw;
            width: 52vw;
            height: calc(100% - 88px);
        }
    }

    .image {
        position: relative;

        @include breakpoint(xlarge) {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;

            picture {
                height: 100%;
                width: 100%;
                position: absolute;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }
        }
    }

    .content {
        z-index: 1;
    }

    .heading-h1 {
        margin-bottom: 16px;

        @include breakpoint(large down) {
            margin-top: 8px;
        }

        @include breakpoint(xlarge) {
            margin-bottom: 58px;
        }
    }

    video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
    }
}

;@import "sass-embedded-legacy-load-done:100";