@import "../../../src/scss/themes/basic/abstracts/custom_variables";
@import "../../../src/scss/themes/basic/abstracts/custom_mixins";
@import "_default/block_hero_home.scss";

.block_hero_home {
    background: linear-gradient(84deg, $color-gradient-secondary 27.31%, $color-primary 77.62%);

    .heading-h1 {
        color: $color-white;
    }

    p {
        color: $color-white;
        @include font-text-l;
    }
}
